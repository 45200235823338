import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import dgReducerManager  from "dg-utils/build/dgReducerManager";
// Import all static reducers
import * as reducers from "./reducers/index";

// Add the router reducer to the static reducers
var staticReducers = reducers;

// Create the reducer management object which will allow dynamic adding and removing of reducers during app operation
const reducerManager = dgReducerManager(staticReducers);

const middleware = [thunk];

// Check if the state has already been created and exists in the window object
let initialState;
if (
    // If the server side has already created the store for us
    typeof window !== "undefined" &&
    window.__PRELOADED_STATE__ &&
    Object.keys(window.__PRELOADED_STATE__).length > 0
) {
    initialState = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
} else {
    initialState = {};
}
//initialState = {};

// Create a store with the root reducer function being the one exposed by the manager.
export const store = createStore(
    reducerManager.reduce,
    initialState,
    compose(
        composeWithDevTools(
            applyMiddleware( ...middleware)
        )
    )
);

// Put the reducer manager on the store so it is easily accessible
store.reducerManager = reducerManager;
