import React, { lazy, Suspense } from "react";
import "./styles/App.scss";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { store } from "./store";
import { ProtectedRoute, Sidebar } from "dg-admin";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

// Library Styles
import "redux-blogs/build/index.css";
import "dg-utils/build/index.css";
import "dg-forms/build/index.css";
import "dg-pagination/build/index.css";

//User Facing Pages
const Home = lazy(() => import("./pages/home/home.component"));
const Team = lazy(() => import("./pages/team/team.component"));
const IndividualClients = lazy(() =>
    import("./pages/individual_clients/individualClients.component")
);
const CorporateClients = lazy(() =>
    import("./pages/corporate_clients/corporateClients.component")
);
const Appendix = lazy(() => import("./pages/appendix/appendix.component"));

// Admin Pages
const UIKit = lazy(() => import("./pages/uiKit/uiKit.component"));
const LoginPage = lazy(() => import("./components/login/login.component"));
const AdminContactList = lazy(() =>
    import("./components/admin/contact/list.component")
);
const AdminContactView = lazy(() =>
    import("./components/admin/contact/view.component")
);

const AdminUserList = lazy(() =>
    import("./pages/admin/users/adminUserList.component")
);
const AdminCreateUser = lazy(() =>
    import("./pages/admin/users/create.component")
);
const AdminUpdateUser = lazy(() =>
    import("./pages/admin/users/update.component")
);

function App() {
    return (
        <Suspense fallback={<div>.</div>}>
            <Routes>
                <Route
                    path="/ui"
                    exact
                    element={<DgLazyComponent Component={UIKit} />}
                />
                <Route
                    path="/"
                    exact
                    element={<DgLazyComponent Component={Home} />}
                />
                <Route
                    path="/our-team"
                    exact
                    element={<DgLazyComponent Component={Team} />}
                />
                {/*
                <Route
                    path="/individual-clients"
                    exact
                    element={<DgLazyComponent Component={IndividualClients} />}
                />
                */}
                <Route
                    path="/client-services"
                    exact
                    element={<DgLazyComponent Component={CorporateClients} />}
                />
                <Route
                    path="/appendix"
                    exact
                    element={<DgLazyComponent Component={Appendix} />}
                />
                <Route
                    path="/admin"
                    exact
                    element={
                        <LoginPage
                            createAccount={
                                process.env.REACT_APP_ENABLE_SIGNUP === "true"
                            }
                        />
                    }
                />

                <Route
                    path="/admin/contacts"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminContactList}
                        />
                    }
                />
                <Route
                    path="/admin/contact/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminContactView}
                        />
                    }
                />
                <Route
                    path="/admin/users"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminUserList}
                        />
                    }
                />
                <Route
                    path="/admin/users/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminCreateUser}
                        />
                    }
                />
                <Route
                    path="/admin/users/update/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminUpdateUser}
                        />
                    }
                />
            </Routes>
        </Suspense>
    );
}

export default App;
